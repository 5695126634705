<template>
  <form id="contact-form" @submit.prevent="processForm" ref="contactform">
      <div class="messages" ref="messages">
        <div class="alert alert-success" v-if="isSuccess">
          {{isSuccess}}
        </div>
        <div v-if="errors.length" class="alert alert-danger">
          <b>Please correct the following error(s):</b>
            <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
        </div>
      </div>
  
    <div class="form-group">
        <input v-model="name" type="text" class="email-bt" placeholder="Name" name="name" minlength="3" />
    </div>
    <div class="form-group">
        <input v-model="email" type="email" class="email-bt" placeholder="Email" name="email" />
    </div>
    <div class="form-group">
        <textarea v-model="comment" class="message-bt" placeholder="Message" rows="5" id="comment" name="comment"></textarea>
    </div>

    <!-- reCAPTCHA -->
    <div class="g-recaptcha" ref="recaptcha" data-sitekey="6Lf6X9waAAAAACGzsveJLdywJ2XKtr7uylEXLh5I"></div>

    <div class="send_btn">
        <button type="submit" class="main_bt btn btn-submit" v-bind:disabled="isLoading" v-bind:class="{ loading: isLoading }">
          Send
          <div class="sk-chase loader">
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
        </div>
        </button>
    </div>

  </form>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    
  },
  data() {
    return {
      name: '',
      email: '',
      comment: '',
      isLoading: false,
      errors: {},
      isSuccess: false
    }
  },
  methods: {
    checkForm: function (e) {
      this.errors = [];
      this.isSuccess = false;

      if (!this.name) {
        this.errors.push('Name required.');
      }
      if (!this.email) {
        this.errors.push('Email required.');
      }
      if (!this.comment) {
        this.errors.push('Message required.');
      }

      e.preventDefault();
    },
    async processForm(e) {
      this.checkForm(e);
      if (this.errors.length > 0) {
        this.$refs.messages.scrollIntoView();
        return;
      }
      this.isLoading = true;
      const s = {
        name: this.name,
        email: this.email,
        comment: this.comment,
        'g-recaptcha-response': document.querySelector('#g-recaptcha-response').value
      }
      const requestOptions = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          Accept: 'application/json',
          cache: 'no-cache'
        },
        body: JSON.stringify(s)
      };
      const response = await fetch('/mail.php', requestOptions);
      const data = await response.json();
      this.isLoading = false;
      
      if (data.status === true) {
        this.isSuccess = data.message;
        this.name = '';
        this.email = '';
        this.comment = '';
      } else {
        this.errors.push(data.message);
      }
      this.$refs.messages.scrollIntoView();
    } 
  }
})
</script>

<style lang="scss">
  .send_btn button:hover, .send_btn button:focus {
      background: #9B3006;
  }

  .sk-chase {
    width: 30px;
    height: 30px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
  }

  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }

  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }

  .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }

  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }

  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }
  
  button.btn-submit {
    position: relative;
    width: 100%;
    
      .loader {
        position: absolute;
        top: 8px;
        left: 46%;
        display: none;
      }
      
      &.loading {
        .loader {
          display: block;
        }
      }
  }

</style>
