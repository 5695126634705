<template>
  <HeadCarousel />
  <!--services start -->
    <div class="services_main">
      <div class="container">
        <div class="creative_taital">
          <h1 class="creative_text">ABOUT US</h1>
          <p style="color: #050000; text-align: center;">Hartley Service Centre is a family run business with over 40 years experience providing our customers with dealership quality work at affordable prices. All our fully qualified technicians are trained to manufacturer standard.
                    We aim to provide a high level of customer care and are proud to be a registered member of The Good Garage Scheme</p>

        </div>
      </div>
  </div>
  <!--services end -->
</template>

<script>
import { defineComponent } from 'vue'
import HeadCarousel from '@/components/HeadCarousel.vue'
export default defineComponent({
  name: 'Home',
  components: {
    HeadCarousel
  }
})
</script>
