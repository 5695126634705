<template>
  <div class="touch_section">
    <div class="container">
      <h1 class="touch_text">Let's Get In Touch!</h1>
    </div>
  </div>

  <div class="lets_touch_main">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="input_main">

            <contact-form />
            
          </div>
        </div>
        <div class="col-sm-12 col-lg-8">
          <div id="map" style=""></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ContactForm from '@/components/ContactForm.vue'

export default defineComponent({
  components: {
    ContactForm
  },
  setup() {
  },
  mounted() {
    window.checkAndAttachMapScript(window.initMap);
    /* eslint-disable */
    setTimeout(function() {
      
      if(document.querySelector('.g-recaptcha')!==null && document.querySelector('.g-recaptcha').children.length==0){
        grecaptcha.render(document.querySelector('.g-recaptcha'), {
          'sitekey': window.RECAPTCHA_KEY
        });
      }
      
    }, 600);
    /* eslint-enable */
  }
  
})
</script>

<style>
#map {
  min-height: 440px;
  height: 100%;
}
</style>
