<template>
  <div class="services_main">
    <div class="container">
      <div class="section_service_2">
        <h1 class="service_text" style="padding-top: 20px">OUR SERVICES</h1>
      </div>
      <div class="service_main">
        <div class="container">
          <div class="row row-flex">
            <div class="col-md-4 mb-4">
              <div class="written_text h-100">
                <div class="like_icon">
                  <img class="icon" src="@/assets/images/Untitled-2.png" />
                </div>
                <h1 class="written_h1">MOT TESTING</h1>
                <p>MOT £40.00 including free retest</p>
              </div>
            </div>
            <div class="col-md-4 mb-4">
              <div class="written_text h-100">
                <div class="like_icon">
                  <img class="icon" src="@/assets/images/servicing.png" />
                </div>
                <h1 class="written_h1">SERVICING</h1>
                <p>
                  We offer servicing, which complies with industry standards, on
                  all makes and models of vehicles.
                </p>
              </div>
            </div>
            <div class="col-md-4 mb-4">
              <div class="written_text h-100">
                <div class="like_icon">
                  <img class="icon" src="@/assets/images/engine_diagnostics.png" />
                </div>
                <h1 class="written_h1">ENGINE DIAGNOSTICS</h1>
                <p>
                  We use the latest dealer level diagnostic equipment including
                  Snap On and Bosch to provide fault diagnostics on all makes
                  and models of vehicles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="service_main">
        <div class="container">
          <div class="row row-flex">
            <div class="col-md-4 mb-4">
              <div class="written_text h-100">
                <div class="like_icon">
                  <img class="icon" src="@/assets/images/body_repairs.png" />
                </div>
                <h1 class="written_h1">BODY REPAIRS</h1>
                <p>
                  We use an affiliated Body Shop to carry out crash repairs and
                  paint work.
                </p>
              </div>
            </div>
            <div class="col-md-4 mb-4">
              <div class="written_text h-100">
                <div class="like_icon">
                  <img class="icon" src="@/assets/images/diagnostics.png" />
                </div>
                <h1 class="written_h1">ELECTRICAL FAULTS</h1>
                <p>
                  Using the latest dealer level diagnostic equipment we are able
                  to carry out electrical repairs on all makes and models of
                  vehicles
                </p>
              </div>
            </div>
            <div class="col-md-4 mb-4">
              <div class="written_text h-100">
                <div class="like_icon">
                  <img class="icon" src="@/assets/images/tyres1.png" />
                </div>
                <h1 class="written_h1">TYRES</h1>
                <p>
                  We are able to offer a large collection of tyres for all makes
                  and models of cars and carry out Balco Laser four wheel
                  alignments
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
